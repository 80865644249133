import { useSelector } from "react-redux"

export const getAuthConfig = (getState) => {
  const {
    users: {
      userLogin: { userInfo },
    },
  } = getState()

  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userInfo.token}`,
    },
  }
}

export const useAuthConfigSelector = () => {
  const userInfo = useSelector((state) => state.users.userLogin.userInfo)
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userInfo.token}`,
    },
  }
}
