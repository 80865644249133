import React from "react"
import { useParams } from "react-router-dom"

import { baseURL } from "../../utils/base"
import GenericRevisionList from "./GenericRevisionList"

const entityConfigs = {
  brands: {
    apiBaseUrl: `${baseURL}v1/brands`,
    displayFields: [
      { key: "version", label: "Version" },
      { key: "actionType", label: "Action" },
      {
        key: "updatedBy",
        label: "Updated By",
      },
      {
        key: "createdAt",
        label: "Date",
        format: (date) => new Date(date).toLocaleString(),
      },
    ],
  },
}

const GenericRevisionRouter = () => {
  const { entityType, entityId } = useParams()
  const config = entityConfigs[entityType.toLowerCase()]

  if (!config) return <div>Entity type not supported</div>

  return (
    <GenericRevisionList
      entityType={entityType}
      entityId={entityId}
      apiBaseUrl={config.apiBaseUrl}
      displayFields={config.displayFields}
    />
  )
}

export default GenericRevisionRouter
