// GenericRevisionList.jsx
import React, { useState, useEffect } from "react"
import axios from "axios"
import PropTypes from "prop-types"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Box,
  Grid,
} from "@mui/material"
import ReactDiffViewer from "react-diff-viewer"
import { config } from "../../utils/globals"

const GenericRevisionList = ({
  entityType, // e.g., "brands"
  entityId,
  apiBaseUrl, // e.g., "/api/brands"
  displayFields, // Fields to show in the table
  onRevert, // Optional callback for revert action
}) => {
  const [revisions, setRevisions] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selectedRevision, setSelectedRevision] = useState(null)
  const [currentVersion, setCurrentVersion] = useState(null)
  const [compareLoading, setCompareLoading] = useState(false)

  useEffect(() => {
    fetchRevisions()
  }, [entityId])

  const fetchRevisions = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${apiBaseUrl}/${entityId}/revisions`)
      setRevisions(response.data)
      setLoading(false)
    } catch (err) {
      setError(err.message)
      setLoading(false)
    }
  }

  const fetchCurrentVersion = async () => {
    try {
      setCompareLoading(true)
      const token = config()
      const response = await axios.get(`${apiBaseUrl}/${entityId}`, token)
      setCurrentVersion(response.data)
      setCompareLoading(false)
    } catch (err) {
      setError(err.message)
      setCompareLoading(false)
    }
  }

  const handleRevert = async (revisionId) => {
    if (window.confirm(`Revert ${entityType} to this revision?`)) {
      try {
        await axios.post(`${apiBaseUrl}/${entityId}/revert`, { revisionId })
        fetchRevisions()
        setSelectedRevision(null) // Close details if reverting
        setCurrentVersion(null)
        if (onRevert) onRevert(revisionId)
        alert("Successfully reverted")
      } catch (err) {
        alert(`Revert failed: ${err.message}`)
      }
    }
  }

  const handleVersionClick = async (revision) => {
    if (selectedRevision?._id === revision._id) {
      setSelectedRevision(null)
      setCurrentVersion(null)
    } else {
      setSelectedRevision(revision)
      await fetchCurrentVersion()
    }
  }

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
        <Typography variant="body1" sx={{ ml: 2 }}>
          Loading revisions...
        </Typography>
      </Box>
    )
  }

  if (error) {
    return (
      <Box p={2}>
        <Typography variant="h6" color="error">
          Error: {error}
        </Typography>
      </Box>
    )
  }

  return (
    <Box p={1} sx={{ width: "100%" }}>
      <Typography variant="h5" gutterBottom>
        {entityType} Revision History
      </Typography>
      <Grid container spacing={2} sx={{ width: "100%" }}>
        {/* Left Side: Revision List */}
        <Grid item xs={12} md={4}>
          {revisions.length === 0 ? (
            <Typography variant="body1">No revisions found</Typography>
          ) : (
            <TableContainer component={Paper} elevation={3}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                    {displayFields.map((field) => (
                      <TableCell key={field.key}>
                        <Typography variant="subtitle2" fontWeight="bold">
                          {field.label}
                        </Typography>
                      </TableCell>
                    ))}
                    <TableCell>
                      <Typography variant="subtitle2" fontWeight="bold">
                        Actions
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {revisions.map((revision) => (
                    <TableRow key={revision._id} hover>
                      {displayFields.map((field) => (
                        <TableCell key={field.key}>
                          {field.key === "version" ? (
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() => handleVersionClick(revision)}
                              sx={{
                                textTransform: "none",
                                padding: 0,
                                minWidth: "auto",
                              }}
                            >
                              {field.format
                                ? field.format(revision[field.key])
                                : revision[field.key] || "N/A"}
                            </Button>
                          ) : field.format ? (
                            field.format(revision[field.key])
                          ) : (
                            revision[field.key] || "N/A"
                          )}
                        </TableCell>
                      ))}
                      <TableCell>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => handleRevert(revision._id)}
                          sx={{ textTransform: "none" }}
                        >
                          Revert
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>

        {/* Right Side: Version Details with Diff */}
        <Grid item xs={12} md={6} sx={{ width: "100%" }}>
          {selectedRevision && (
            <Paper elevation={3} sx={{ p: 2, width: "100%" }}>
              <Typography variant="h6" gutterBottom>
                Version {selectedRevision.version} Details
              </Typography>
              {compareLoading ? (
                <Box display="flex" alignItems="center">
                  <CircularProgress size={24} />
                  <Typography variant="body2" sx={{ ml: 2 }}>
                    Loading current version...
                  </Typography>
                </Box>
              ) : currentVersion ? (
                <>
                  <Typography variant="subtitle1" gutterBottom>
                    Comparison: Version {selectedRevision.version} vs Current
                  </Typography>
                  <ReactDiffViewer
                    oldValue={JSON.stringify(
                      selectedRevision.snapshot,
                      null,
                      2
                    )}
                    newValue={JSON.stringify(currentVersion, null, 2)}
                    splitView={true}
                    showDiffOnly={false}
                    extraLinesSurroundingDiff={2}
                    styles={{
                      variables: {
                        light: {
                          diffViewerBackground: "#f5f5f5",
                          addedBackground: "#e6ffe6",
                          removedBackground: "#ffe6e6",
                          wordAddedBackground: "#ccffcc",
                          wordRemovedBackground: "#ffcccc",
                        },
                      },
                    }}
                  />
                </>
              ) : (
                <Typography variant="body2">
                  Failed to load current version
                </Typography>
              )}
              <Button
                variant="outlined"
                onClick={() => {
                  setSelectedRevision(null)
                  setCurrentVersion(null)
                }}
                sx={{ mt: 2 }}
              >
                Close
              </Button>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

GenericRevisionList.propTypes = {
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  apiBaseUrl: PropTypes.string.isRequired,
  displayFields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      format: PropTypes.func, // Optional formatter
    })
  ).isRequired,
  onRevert: PropTypes.func,
}

export default GenericRevisionList
