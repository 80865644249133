import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { hideConfirmationPopup } from "../../state/actions/confirmationActions"

import "./ConfirmationPopup.scss"
import Button from "./CustomButton"

const ConfirmationPopup = () => {
  const dispatch = useDispatch()
  const { isOpen, title, content, onConfirm, cancelLabel, confirmLabel } =
    useSelector((state) => state.ui.confirmation)

  if (!isOpen) return null

  const handleConfirm = () => {
    if (onConfirm) onConfirm() // Execute the confirm action
    dispatch(hideConfirmationPopup()) // Close the popup
  }

  const handleCancel = () => {
    dispatch(hideConfirmationPopup()) // Just close the popup
  }

  return (
    <div className="popup-status-change">
      <div className="popup-status-change-inner">
        <div className="title">{title}</div>
        <p>{content}</p>
        <div className="status-button-wrapper">
          <Button className="btn light" onClick={handleCancel}>
            {cancelLabel}
          </Button>
          <Button className="btn primary" onClick={handleConfirm}>
            {confirmLabel}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationPopup
