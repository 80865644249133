import {
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAIL_FAIL,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
} from "../constants/userContants"

import axios from "../../utils/axios.js"
import {
  OPPORTUNITY_LIST_FAIL,
  OPPORTUNITY_LIST_REQUEST,
  OPPORTUNITY_LIST_SUCCESS,
} from "../constants/opportunityConstants"
import { getAuthConfig } from "./actionUtils"

// Helper function to extract error messages
const getErrorMessage = (error) =>
  error.response && error.response.data.message
    ? error.response.data.message
    : error.message

// Login does not require existing auth headers.
export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST })

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }

    const { data } = await axios.post(
      `/v1/users/login`,
      { username, password },
      config
    )
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data })

    localStorage.setItem("userInfo", JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: getErrorMessage(error),
    })
  }
}

// Create a new user (requires auth)
export const createUser = (user, type) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_CREATE_REQUEST })

    const config = getAuthConfig(getState)
    const { data } = await axios.post(`/v1/users?type=${type}`, user, config)
    dispatch({ type: USER_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USER_CREATE_FAIL,
      payload: getErrorMessage(error),
    })
  }
}

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo")
  dispatch({ type: USER_LOGOUT })
}

// Get list of users (requires auth)
export const getUserList =
  (type, status = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: USER_LIST_REQUEST })

      const config = getAuthConfig(getState)
      const { data } = await axios.get(
        `/v1/users/?type=${type}&status=${status}`,
        config
      )
      dispatch({ type: USER_LIST_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload: getErrorMessage(error),
      })
    }
  }

// Get list of users by dealership (requires auth)
export const getUserListByDealership =
  (dealershipId) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_LIST_REQUEST })

      const config = getAuthConfig(getState)
      const { data } = await axios.get(
        `/v1/users/dealership/${dealershipId}`,
        config
      )
      dispatch({ type: USER_LIST_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload: getErrorMessage(error),
      })
    }
  }

// Get user detail (requires auth)
export const getUserDetail = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DETAIL_REQUEST })

    const config = getAuthConfig(getState)
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    // If the logged-in user is an admin, use the standard endpoint,
    // otherwise use the officialBrands endpoint.
    const { data } = userInfo._admin
      ? await axios.get(`/v1/users/${id}`, config)
      : await axios.get(`/v1/officialBrands/user/`, config)

    dispatch({ type: USER_DETAIL_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USER_DETAIL_FAIL,
      payload: getErrorMessage(error),
    })
  }
}

// Get opportunity list for a user (requires auth)
export const getUserOpportunityList = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: OPPORTUNITY_LIST_REQUEST })

    const config = getAuthConfig(getState)
    const { data } = await axios.get(`/v1/users/${user}/opportunities`, config)
    dispatch({ type: OPPORTUNITY_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: OPPORTUNITY_LIST_FAIL,
      payload: getErrorMessage(error),
    })
  }
}

// Update a user (requires auth)
export const updateUser = (id, user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_REQUEST })

    const config = getAuthConfig(getState)
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const { data } = userInfo._admin
      ? await axios.put(`/v1/users/${id}?type=admin`, user, config)
      : userInfo._officialBrand
      ? await axios.put(`/v1/officialBrands/user`, user, config)
      : null
    dispatch({ type: USER_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: getErrorMessage(error),
    })
  }
}

// Delete a user (requires auth)
export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DELETE_REQUEST })

    const config = getAuthConfig(getState)
    const { data } = await axios.delete(`/v1/users/${id}`, config)
    dispatch({ type: USER_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload: getErrorMessage(error),
    })
  }
}
