import { configureStore } from "@reduxjs/toolkit"
import { thunk } from "redux-thunk"
import { combineReducers } from "@reduxjs/toolkit"
import {
  brandCreateReducer,
  brandDetailReducer,
  brandListReducer,
  brandListReviewReducer,
  brandUpdateReducer,
} from "./reducers/brandReducers"
import {
  userCreateReducer,
  userDeleteReducer,
  userDetailReducer,
  userListReducer,
  userLoginReducer,
  userUpdateReducer,
} from "./reducers/userReducers"
import {
  modelCreateReducer,
  modelDeleteReducer,
  modelDetailListReducer,
  modelDetailReducer,
  modelListReducer,
  modelListReviewReducer,
  modelUpdateReducer,
} from "./reducers/modelReducers"
import {
  generationDeleteReducer,
  generationDetailReducer,
  generationListReducer,
} from "./reducers/generationReducers"

import {
  carAuctionListReducer,
  carCreateReducer,
  carDeleteReducer,
  carDetailReducer,
  carFilterOptionReducer,
  carImageUpdateReducer,
  carListReducer,
  carOpportunityListReducer,
  carUpdateReducer,
} from "./reducers/carMpReducers"
import {
  trimByYearListReducer,
  trimCreateReducer,
  trimDeleteReducer,
  trimDetailReducer,
  trimListFilterReducer,
  trimListReducer,
  trimUpdateReducer,
} from "./reducers/trimReducers"
import {
  yearByModelGenerationListReducer,
  yearCreateReducer,
  yearDeleteReducer,
  yearDetailReducer,
  yearListReducer,
  yearListReviewReducer,
  yearRangeCreateReducer,
  yearUpdateReducer,
} from "./reducers/yearReducers"
import {
  noteCreateReducer,
  noteDeleteReducer,
  noteListReducer,
  noteUpdateReducer,
  taskListReducer,
} from "./reducers/commonReducers"
import {
  opportunityCreateReducer,
  opportunityDeleteReducer,
  opportunityListReducer,
  opportunityUpdateReducer,
} from "./reducers/opportunityReducers"
import {
  sellerCreateReducer,
  sellerDeleteReducer,
  sellerDetailReducer,
  sellerDuplicateCheckReducer,
  sellerListReducer,
  sellerTagsListReducer,
  sellerUpdateReducer,
} from "./reducers/sellerReducers"
import { staticDataListReducer } from "./reducers/staticDataReducer"
import {
  eventCreateReducer,
  eventDeleteReducer,
  eventsListReducer,
  eventUpdateReducer,
} from "./reducers/eventsReducers"
import { sellerLastActivityReducer } from "./reducers/sellerAnalyticsReducers"
import { modelPriceTrendReducer } from "./reducers/analyticsReducers"
import { notificationsChangeReducer } from "./reducers/notificationReducers"
import {
  reviewCreateReducer,
  reviewDetailReducer,
  reviewUpdateReducer,
} from "./reducers/reviewReducers"
import { responseReducer } from "./reducers/responseReducer"
import { sellerSpecificListReducer } from "./reducers/sellerSpecificReducers"
import {
  auctionCreateReducer,
  auctionDeleteReducer,
  auctionDetailReducer,
  auctionListReducer,
  auctionUpdateReducer,
} from "./reducers/auctionReducers"
import {
  logListGroupByCarsReducer,
  logListReducer,
} from "./reducers/logReducers"
import {
  saleCreateReducer,
  saleDeleteReducer,
  saleDetailReducer,
  saleListReducer,
  saleUpdateReducer,
} from "./reducers/saleReducers"
import {
  linkCreateReducer,
  linkDeleteReducer,
  linkDetailReducer,
  linkListReducer,
  linkUpdateReducer,
} from "./reducers/linkReducers"
import { tagsListReducer } from "./reducers/tagReducers"
import {
  yearSpecTemplateCreateReducer,
  yearSpecTemplateDetailReducer,
  yearSpecTemplateListReducer,
  yearSpecTemplateUpdateReducer,
} from "./reducers/carDbTemplateReducers"
import { confirmationPopupReducer } from "./reducers/confirmationReducers"

const reducer = combineReducers({
  users: combineReducers({
    userLogin: userLoginReducer,
    userCreate: userCreateReducer,
    userList: userListReducer,
    userDetail: userDetailReducer,
    userUpdate: userUpdateReducer,
    userDelete: userDeleteReducer,
  }),

  carCatalogue: combineReducers({
    brandList: brandListReducer,
    brandListReview: brandListReviewReducer,
    brandCreate: brandCreateReducer,
    brandDetail: brandDetailReducer,
    brandUpdate: brandUpdateReducer,
    modelList: modelListReducer,
    modelListReview: modelListReviewReducer,
    modelCreate: modelCreateReducer,
    modelDetail: modelDetailReducer,
    modelInfoList: modelDetailListReducer,
    modelUpdate: modelUpdateReducer,
    modelDelete: modelDeleteReducer,
    generationList: generationListReducer,
    generationDelete: generationDeleteReducer,
    generationDetail: generationDetailReducer,
    yearList: yearListReducer,
    yearListReview: yearListReviewReducer,
    yearDetail: yearDetailReducer,
    yearByGenList: yearByModelGenerationListReducer,
    yearCreate: yearCreateReducer,
    yearRangeCreate: yearRangeCreateReducer,
    yearUpdate: yearUpdateReducer,
    yearDelete: yearDeleteReducer,
    trimList: trimListReducer,
    trimByYearList: trimByYearListReducer,
    trimCreate: trimCreateReducer,
    trimDetail: trimDetailReducer,
    trimUpdate: trimUpdateReducer,
    trimDelete: trimDeleteReducer,
    trimListFilter: trimListFilterReducer,
  }),
  seller: combineReducers({
    sellerList: sellerListReducer,
    sellerUpdate: sellerUpdateReducer,
    sellerDuplicateCheckList: sellerDuplicateCheckReducer,
    sellerCreate: sellerCreateReducer,
    sellerDetail: sellerDetailReducer,
    sellerTagsList: sellerTagsListReducer,
    sellerDelete: sellerDeleteReducer,
    sellerSpecificList: sellerSpecificListReducer,
  }),
  carMarketplace: combineReducers({
    carList: carListReducer,
    carUpdate: carUpdateReducer,
    carOptions: carFilterOptionReducer,
    carDetail: carDetailReducer,
    carImagesUpdate: carImageUpdateReducer,
    carOpportunitiesList: carOpportunityListReducer,
    carAuctionList: carAuctionListReducer,
    carCreate: carCreateReducer,
    carDelete: carDeleteReducer,
  }),
  opportunity: combineReducers({
    opportunityList: opportunityListReducer,
    opportunityUpdate: opportunityUpdateReducer,
    opportunityCreate: opportunityCreateReducer,
    opportunityDelete: opportunityDeleteReducer,
  }),
  common: combineReducers({
    noteList: noteListReducer,
    noteUpdate: noteUpdateReducer,
    noteCreate: noteCreateReducer,
    noteDelete: noteDeleteReducer,
    taskList: taskListReducer,
  }),
  staticData: combineReducers({
    staticDataList: staticDataListReducer,
  }),
  event: combineReducers({
    eventList: eventsListReducer,
    eventCreate: eventCreateReducer,
    eventUpdate: eventUpdateReducer,
    eventDelete: eventDeleteReducer,
  }),
  analytics: combineReducers({
    sellerLastActivity: sellerLastActivityReducer,
    modelPriceTrend: modelPriceTrendReducer,
  }),
  notifications: combineReducers({
    notificationsChange: notificationsChangeReducer,
  }),
  review: combineReducers({
    reviewCreate: reviewCreateReducer,
    reviewUpdate: reviewUpdateReducer,
    reviewDetail: reviewDetailReducer,
  }),
  auction: combineReducers({
    auctionList: auctionListReducer,
    auctionCreate: auctionCreateReducer,
    auctionDetail: auctionDetailReducer,
    auctionUpdate: auctionUpdateReducer,
    auctionDelete: auctionDeleteReducer,
  }),
  sale: combineReducers({
    saleList: saleListReducer,
    saleCreate: saleCreateReducer,
    saleDetail: saleDetailReducer,
    saleUpdate: saleUpdateReducer,
    saleDelete: saleDeleteReducer,
  }),
  response: combineReducers({
    messageState: responseReducer,
  }),
  log: combineReducers({
    logList: logListReducer,
    logListGroupByCars: logListGroupByCarsReducer,
  }),
  link: combineReducers({
    linkCreate: linkCreateReducer,
    linkList: linkListReducer,
    linkUpdate: linkUpdateReducer,
    linkDetail: linkDetailReducer,
    linkDelete: linkDeleteReducer,
  }),
  tags: combineReducers({
    tagsList: tagsListReducer,
  }),
  carDbTemplate: combineReducers({
    yearSpecTemplateCreate: yearSpecTemplateCreateReducer,
    yearSpecTemplateList: yearSpecTemplateListReducer,
    yearSpecTemplateDetail: yearSpecTemplateDetailReducer,
    yearSpecTemplateUpdate: yearSpecTemplateUpdateReducer,
  }),
  ui: combineReducers({ confirmation: confirmationPopupReducer }),
})

const UserInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null

const initialState = { users: { userLogin: { userInfo: UserInfoFromStorage } } }

const store = configureStore({
  reducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.REACT_APP_ENV !== "PUBLIC-PROD", // Enables DevTools in non-prod environments
})

export { store }
