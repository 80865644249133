import React from "react"
import "./NotificationCard.scss"
import usericon from "../../images/common/usericon.svg"
import usericonlight from "../../images/common/user-light.svg"
import readicon from "../../images/common/dotRead.svg"
import unreadicon from "../../images/common/dotUnread.svg"
import moment from "moment" // Add this import

export const NotificationMobile = ({
  notification,
  handleNotificationClick,
}) => {
  return (
    <li
      key={notification.title}
      className={
        notification.isRead
          ? "notification-card read"
          : "notification-card unread"
      }
      onClick={() => handleNotificationClick(notification)}
    >
      <div
        className={
          notification.isRead
            ? "notification-wrapper read"
            : "notification-wrapper unread"
        }
      >
        <div className="c1">
          <img
            src={!notification.isRead ? usericon : usericonlight}
            alt=""
            className="icon"
          />
        </div>
        <div className="c2">
          <p className="text-title">{notification.title}</p>
          <div className="flex aic">
            <p className="text-sub-title">
              {notification.body}
              {` · `}
            </p>
            <small className="text-light">
              {moment(notification.createdAt).fromNow()}
            </small>
          </div>
          <div className="text-light">
            {notification?._user?._officialBrand ? (
              <p>
                {notification?._user?.role?.name
                  .toLowerCase()
                  .includes("manager")
                  ? "Manager"
                  : "Staff"}{" "}
                at {notification._user._branch.name} -{" "}
                {notification._user._officialBrand.name}
              </p>
            ) : notification._user._admin ? (
              <p>Carmate Admin</p>
            ) : null}
          </div>
        </div>
        <div className="c3">
          {notification.isRead ? (
            <img src={readicon} alt="" />
          ) : (
            <img src={unreadicon} alt="" />
          )}
        </div>
      </div>
    </li>
  )
}
