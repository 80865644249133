import React from "react"
import { Button as MuiButton } from "@mui/material"
import "./Button.scss"

const Button = (props) => {
  return (
    <div>
      <MuiButton
        className={props.className}
        onClick={props.onClick}
        disabled={props.disabled}
        variant={props.variant}
      >
        {props.children}
      </MuiButton>
    </div>
  )
}

export default Button
