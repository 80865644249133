import React, { useState } from "react"
import { Button, useMediaQuery } from "@mui/material"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import "./Dropdown.scss"
import usericon from "../../images/common/usericon.svg"

import dropdownarrow from "../../images/header/dropdownarrow.svg"
import { useDispatch } from "react-redux"
import { logout } from "../../state/actions/userActions"
import { useNavigate } from "react-router-dom"

const DropDownMenu = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isMobile = useMediaQuery("(max-width: 600px)")

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const logOutHandler = () => {
    dispatch(logout())
  }

  return (
    <div className="dropdown-menu">
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="dropdown"
      >
        {!isMobile ? (
          <>
            <p className="text">{user.firstName || user.lastName}</p>{" "}
            <img src={dropdownarrow} alt="icon" className="arrow" />
            <img
              src={user.profileImage || usericon}
              alt=""
              className="user-image"
            />
          </>
        ) : (
          <img
            src={user.profileImage || usericon}
            alt=""
            className="profile-image"
          />
        )}
        {}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ width: "200px" }}
      >
        <MenuItem
          onClick={() => {
            navigate(`/user/edit`)
            handleClose()
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            logOutHandler()
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  )
}

export default DropDownMenu
