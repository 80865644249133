import React, { useState, useEffect, Suspense, lazy, useRef } from "react"
import { Route, Routes, useNavigate, useLocation } from "react-router-dom"
import SideBar from "./components/sidebar/SideBar"
import SideDrawer from "./components/sidedrawer/SideDrawer"
import Backdrop from "./components/backdrop/Backdrop"
import Header from "./components/header/Header"
import Container from "./components/utils/Container"
import ErrorHandling from "./components/utils/ErrorHandling"
import CustomMessage from "./components/utils/CustomMessage"
import { useDispatch, useSelector } from "react-redux"
import { createCar, updateCar } from "./state/actions/carMpActions"
import "./App.scss"
import { ProtectedRoute } from "./components/utils/ProtectedRoute"

import { io } from "socket.io-client"
import { changeNotifications } from "./state/actions/notificationsAction"
import { baseURL } from "./utils/base"
import ConfirmationPopup from "./components/common/ConfirmationPopup"
import GenericRevisionRouter from "./screens/revisions/GenericRevisionRouter"

// Lazy load screens
const LoginScreen = lazy(() => import("./screens/authscreens/LoginScreen"))
const DashboardScreen = lazy(() =>
  import("./screens/dashboard/DashboardScreen")
)
const CarListScreen = lazy(() =>
  import("./screens/carsmpscreens/CarListScreen")
)
const SellerListScreen = lazy(() =>
  import("./screens/sellerscreens/SellerListScreen")
)
const CarCatalogueScreen = lazy(() =>
  import("./screens/carctscreens/CarCatalogueScreen")
)
const CarDetailScreen = lazy(() =>
  import("./screens/carsmpscreens/CarDetailScreen")
)
const OpportunityListScreen = lazy(() =>
  import("./screens/opportunityscreens/OpportunityListScreen")
)
const UserListScreen = lazy(() =>
  import("./screens/userscreens/UserListScreen")
)
const UserDetailScreen = lazy(() =>
  import("./screens/userscreens/UserDetailScreen")
)
const UserEditScreen = lazy(() =>
  import("./screens/userscreens/UserEditScreen")
)
const OpportunityCreateScreen = lazy(() =>
  import("./screens/opportunityscreens/OpportunityCreateScreen")
)
const AdvertisementListScreen = lazy(() =>
  import("./screens/advertisementscreens/AdvertisementListScreen")
)
const CarTrendScreen = lazy(() => import("./screens/analytics/AnalyticsScreen"))
const SellerDetailScreen = lazy(() =>
  import("./screens/sellerscreens/SellerDetailScreen")
)

const TrimCreateEditScreen = lazy(() =>
  import("./screens/carctscreens/TrimCreateEditScreen")
)
const YearCreateEditScreen = lazy(() =>
  import("./screens/carctscreens/YearCreateEditScreen")
)
const AuctionListScreen = lazy(() =>
  import("./screens/auctionscreen/AuctionListScreen")
)
const AuctionCreateEditScreen = lazy(() =>
  import("./screens/auctionscreen/AuctionCreateEditScreen")
)
const CarCreateEditScreen = lazy(() =>
  import("./screens/carsmpscreens/CarCreateEditScreen")
)
const SellerCreateEditScreen = lazy(() =>
  import("./screens/sellerscreens/SellerCreateEditScreen")
)
const LogListingScreen = lazy(() =>
  import("./screens/logscreens/LogListingScreen")
)
const EventListScreen = lazy(() =>
  import("./screens/eventscreens/EventListScreen")
)
const EventCreateEditScreen = lazy(() =>
  import("./screens/eventscreens/EventCreateEditScreen")
)
const LinkCreateEditScreen = lazy(() =>
  import("./screens/linkscreens/LinkCreateEditScreen")
)
const LinkListScreen = lazy(() =>
  import("./screens/linkscreens/LinkListScreen")
)
const SettingsDashboardScreen = lazy(() =>
  import("./screens/settingsscreens/SettingsDashboardScreen")
)
const NewCarTrimsDashboard = lazy(() =>
  import("./screens/newcartrimscreens/NewCarTrimsDashboard")
)

const App = () => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false)
  const [carId, setCarId] = useState(null)

  const [time, setTime] = useState("")

  const socket = useRef(null)
  const dispatch = useDispatch()

  const notifications = useSelector(
    (state) => state.notifications.notificationsChange
  )
  const { notify } = notifications

  useEffect(() => {
    // Initialize the socket connection
    socket.current = io(`${baseURL}`, {
      reconnectionAttempts: 5, // Set max reconnection attempts
      reconnectionDelay: 5000, // Delay before reconnecting
    })

    socket.current.on("connect", () => {
      console.log("Socket connected:", socket.current.id)
    })

    socket.current.on("connect_error", (error) => {
      console.error("Socket connection error:", error)
    })

    if (userInfo?._admin) {
      socket.current.on("msg:get", (data) => {
        // Filter notifications for the logged-in admin
        const filteredNotifications = data.msg.filter(
          (notification) => notification._user._id !== userInfo._id
        )
        dispatch(changeNotifications(filteredNotifications))
      })
    } else if (userInfo?._officialBrand) {
      socket.current.on("msg:get_official_brand", (data) => {
        // Filter notifications for the logged-in official brand user
        const filteredNotifications = data.msg.filter(
          (notification) => notification._user._id !== userInfo._id
        )
        dispatch(changeNotifications(filteredNotifications))
      })
    } else {
    }

    socket.current.on("disconnect", () => {
      console.log("Socket disconnected")
      setTime("Server disconnected")
    })

    // Clean up socket connection when component unmounts
    return () => {
      if (socket.current) {
        socket.current.disconnect()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, notify])
  const navigate = useNavigate()
  const userLogin = useSelector((state) => state.users.userLogin)
  const { userInfo } = userLogin

  const location = useLocation()

  useEffect(() => {
    !userInfo
      ? navigate("/login")
      : userInfo && location.pathname === "/login" && navigate("/dashboard")
  }, [userInfo, navigate, location.pathname])

  const uploadHandler = async (car) => {
    dispatch(createCar(car))
  }

  const updateHandler = async (id, carUpdated) => {
    dispatch(updateCar(id, carUpdated))
    setCarId(id)
  }

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen)
  }

  const backdropClickHandler = () => {
    setSideDrawerOpen(false)
  }

  return (
    <>
      {!userInfo ? (
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/login" element={<LoginScreen />} exact />
          </Routes>
        </Suspense>
      ) : (
        <>
          <Container>
            <Header drawerClick={drawerToggleClickHandler} />
            <main className="screen-wrapper flex">
              <SideBar time={time} />
              {sideDrawerOpen && (
                <>
                  <SideDrawer closeDrawer={backdropClickHandler} />
                  <Backdrop click={backdropClickHandler} />
                </>
              )}
              <ConfirmationPopup />
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  {/* Dashboard Routes */}
                  <Route path="/" element={<DashboardScreen />} exact />
                  <Route
                    path="/dashboard"
                    element={<DashboardScreen />}
                    exact
                  />
                  <Route
                    path="/:entityType/:entityId/revisions"
                    element={<GenericRevisionRouter />}
                  />
                  {/* Car Routes */}
                  <Route
                    path="/car/list"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="car"
                        permission="read"
                        element={<CarListScreen carUpdateId={carId} />}
                      />
                    }
                  />
                  <Route
                    path="/car/create"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="car"
                        permission="create"
                        element={
                          <CarCreateEditScreen uploadHandler={uploadHandler} />
                        }
                      />
                    }
                  />
                  <Route
                    path="/car/:id"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="car"
                        permission="read"
                        element={<CarDetailScreen />}
                      />
                    }
                  />
                  <Route
                    path="/car/:id/edit"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="car"
                        permission="update"
                        element={
                          <CarCreateEditScreen updateHandler={updateHandler} />
                        }
                      />
                    }
                  />
                  {/* Catalogue Routes */}

                  <Route
                    path="/catalogue"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="catalogue"
                        permission="read"
                        element={<CarCatalogueScreen />}
                      />
                    }
                  />

                  <Route
                    path="/catalogue/year/:id/edit"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="catalogue"
                        permission="create"
                        element={<YearCreateEditScreen />}
                      />
                    }
                  />
                  <Route
                    path="/catalogue/year/"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="catalogue"
                        permission="create"
                        element={<YearCreateEditScreen />}
                      />
                    }
                  />

                  <Route
                    path="/catalogue/trim/:id/edit"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="catalogue"
                        permission="create"
                        element={<TrimCreateEditScreen />}
                      />
                    }
                  />
                  <Route
                    path="/catalogue/trim/create"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="catalogue"
                        permission="create"
                        element={<TrimCreateEditScreen />}
                      />
                    }
                  />
                  {/* New Car Routes */}
                  <Route
                    path="/trim/dashboard"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="new-car"
                        permission="read"
                        element={<NewCarTrimsDashboard />}
                      />
                    }
                    exact
                  />

                  {/* Seller Routes */}
                  <Route
                    path="/seller/list/"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="seller"
                        permission="read"
                        element={<SellerListScreen />}
                      />
                    }
                  />
                  <Route
                    path="/seller/:sellerType/create"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="seller"
                        permission="create"
                        element={<SellerCreateEditScreen />}
                      />
                    }
                  />
                  <Route
                    path="/seller/:sellerType/:id/edit"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="seller"
                        permission="update"
                        element={<SellerCreateEditScreen />}
                      />
                    }
                  />

                  <Route
                    path="/seller/:sellerType/:id/show"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="seller"
                        permission="read"
                        element={<SellerDetailScreen />}
                      />
                    }
                  />

                  {/* Auction Routes */}
                  <Route
                    path="/auction/list"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="auction"
                        permission="read"
                        element={<AuctionListScreen />}
                      />
                    }
                  />

                  <Route
                    path="/auction/create"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="auction"
                        permission="read"
                        element={<AuctionCreateEditScreen />}
                      />
                    }
                  />

                  <Route
                    path="/auction/:id/edit"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="auction"
                        permission="update"
                        element={<AuctionCreateEditScreen />}
                      />
                    }
                  />

                  {/* Event Routes */}
                  <Route
                    path="/event/list"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="event"
                        permission="read"
                        element={<EventListScreen />}
                      />
                    }
                  />
                  <Route
                    path="/event/create"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="event"
                        permission="create"
                        element={<EventCreateEditScreen />}
                      />
                    }
                  />
                  <Route
                    path="/event/:id/edit"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="event"
                        permission="update"
                        element={<EventCreateEditScreen />}
                      />
                    }
                  />

                  {/* User Routes */}
                  <Route
                    path="/user/list"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="user"
                        permission="read"
                        element={<UserListScreen />}
                      />
                    }
                  />
                  <Route path="/user/edit" element={<UserEditScreen />} />
                  <Route
                    path="/user/:id/show"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="user"
                        permission="update"
                        element={<UserDetailScreen />}
                      />
                    }
                  />

                  {/* Opportunity Routes */}
                  <Route
                    path="/opportunity/list"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="opportunities"
                        permission="read"
                        element={<OpportunityListScreen />}
                      />
                    }
                  />
                  <Route
                    path="/opportunity/create"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="opportunities"
                        permission="read"
                        element={<OpportunityCreateScreen />}
                      />
                    }
                  />

                  {/* Advertisement Routes */}
                  <Route
                    path="/advertisement/list"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="advertisement"
                        permission="create"
                        element={<AdvertisementListScreen />}
                      />
                    }
                  />

                  {/* Trend and Analytics */}
                  <Route
                    path="/trends"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="trends"
                        permission="read"
                        element={<CarTrendScreen />}
                      />
                    }
                  />

                  {/* Log Routes */}
                  <Route
                    path="/logs"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="logs"
                        permission="read"
                        element={<LogListingScreen />}
                      />
                    }
                  />

                  {/* Link Management Routes */}
                  <Route
                    path="/link/"
                    exact
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="links"
                        permission="read"
                        element={<LinkListScreen />}
                      />
                    }
                  />
                  <Route
                    path="/link/create"
                    exact
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="links"
                        permission="create"
                        element={<LinkCreateEditScreen />}
                      />
                    }
                  />
                  <Route
                    path="/link/:id/edit"
                    exact
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="links"
                        permission="update"
                        element={<LinkCreateEditScreen />}
                      />
                    }
                  />

                  {/* Specification Routes */}
                  <Route
                    path="/settings/dashboard"
                    element={
                      <ProtectedRoute
                        userInfo={userInfo}
                        resource="settings"
                        permission="create"
                        element={<SettingsDashboardScreen />}
                      />
                    }
                  />
                </Routes>
              </Suspense>
            </main>
            <ErrorHandling setCarId={setCarId} />
            <CustomMessage />
          </Container>
        </>
      )}
    </>
  )
}

export default App
