import {
  CAR_AUCTION_LIST_FAIL,
  CAR_AUCTION_LIST_REQUEST,
  CAR_AUCTION_LIST_SUCCESS,
  CAR_CREATE_FAIL,
  CAR_CREATE_REQUEST,
  CAR_CREATE_SUCCESS,
  CAR_DELETE_FAIL,
  CAR_DELETE_REQUEST,
  CAR_DELETE_SUCCESS,
  CAR_DETAIL_FAIL,
  CAR_DETAIL_REQUEST,
  CAR_DETAIL_SUCCESS,
  CAR_FILTER_OPTION_FAIL,
  CAR_FILTER_OPTION_REQUEST,
  CAR_FILTER_OPTION_SUCCESS,
  CAR_LIST_FAIL,
  CAR_LIST_REQUEST,
  CAR_LIST_SUCCESS,
  CAR_OPPORTUNITY_LIST_FAIL,
  CAR_OPPORTUNITY_LIST_REQUEST,
  CAR_OPPORTUNITY_LIST_SUCCESS,
  CAR_UPDATE_FAIL,
  CAR_UPDATE_REQUEST,
  CAR_UPDATE_SUCCESS,
} from "../constants/carMPConstants"
import Axios from "../../utils/axios"
import { generateQueryURL, hasPermission } from "../../utils/utilsFunctions"
import { getAuthConfig } from "./actionUtils"

export const getCarList = (queryObject) => async (dispatch, getState) => {
  try {
    dispatch({ type: CAR_LIST_REQUEST })

    // Extract userInfo for conditional logic (if needed)
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    // Use the utility function to get auth headers
    const config = getAuthConfig(getState)

    const { data } = userInfo._admin
      ? await Axios.get(`/v1/cars?${generateQueryURL(queryObject)}`, config)
      : userInfo._officialBrand
      ? hasPermission(userInfo, "car", "read") &&
        (await Axios.get(
          `/v1/officialBrands/cars?${generateQueryURL(queryObject)}`,
          config
        ))
      : null

    dispatch({
      type: CAR_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateCar = (id, car) => async (dispatch, getState) => {
  try {
    dispatch({ type: CAR_UPDATE_REQUEST })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = getAuthConfig(getState)
    const { data } = userInfo._admin
      ? await Axios.put(`/v1/cars/${id}`, car, config)
      : await Axios.put(`/v1/officialBrands/cars/${id}`, car, config)

    dispatch({
      type: CAR_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getFilterOptions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CAR_FILTER_OPTION_REQUEST })

    const config = getAuthConfig(getState)
    const { data } = await Axios.get(`/v1/cars/details/all`, config)

    dispatch({
      type: CAR_FILTER_OPTION_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_FILTER_OPTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCarDetail = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CAR_DETAIL_REQUEST })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = getAuthConfig(getState)
    const { data } = userInfo._admin
      ? await Axios.get(`/v1/cars/${id}?platform=admin`, config)
      : userInfo._officialBrand
      ? await Axios.get(`/v1/officialBrands/cars/${id}?platform=admin`, config)
      : null

    dispatch({
      type: CAR_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteCar = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CAR_DELETE_REQUEST })

    const config = getAuthConfig(getState)
    await Axios.delete(`/v1/cars/${id}`, config)

    dispatch({ type: CAR_DELETE_SUCCESS })
  } catch (error) {
    dispatch({
      type: CAR_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCarOpportunityList = (carId) => async (dispatch, getState) => {
  try {
    dispatch({ type: CAR_OPPORTUNITY_LIST_REQUEST })

    const config = getAuthConfig(getState)
    const { data } = await Axios.get(`/v1/cars/${carId}/opportunities`, config)

    dispatch({
      type: CAR_OPPORTUNITY_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_OPPORTUNITY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createCar = (car) => async (dispatch, getState) => {
  try {
    dispatch({ type: CAR_CREATE_REQUEST })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = getAuthConfig(getState)
    const { data } = userInfo._admin
      ? await Axios.post(`/v1/cars/`, car, config)
      : userInfo._officialBrand && hasPermission(userInfo, "car", "create")
      ? await Axios.post(`/v1/officialBrands/cars/`, car, config)
      : null

    dispatch({
      type: CAR_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Auction Actions

export const getCarAuctionList = (activeTab) => async (dispatch, getState) => {
  try {
    dispatch({ type: CAR_AUCTION_LIST_REQUEST })

    const config = getAuthConfig(getState)
    const { data } = await Axios.get(
      `/v1/cars/auctions?status=${activeTab}&platform=Admin`,
      config
    )

    dispatch({
      type: CAR_AUCTION_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_AUCTION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCarListKeyword = (keyword) => async (dispatch, getState) => {
  try {
    dispatch({ type: CAR_LIST_REQUEST })

    const config = getAuthConfig(getState)
    const { data } = await Axios.get(
      `/v1/cars/keyword?keyword=${keyword}`,
      config
    )

    dispatch({
      type: CAR_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
