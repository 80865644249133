import React from "react"
import { CircularProgress as MuiCircularProgress } from "@mui/material"

const CircularProgress = (props) => {
  return (
    <div>
      <MuiCircularProgress
        className={props.className}
        size={props.size}
        color={props.color}
        variant={props.variant}
        value={props.value}
      />
    </div>
  )
}

export default CircularProgress
