import Axios from "../utils/axios"

/**
 * Returns an Axios configuration object with the auth headers.
 * @param {string} token - The user's authentication token.
 * @returns {Object} Axios configuration.
 */
const getAuthConfig = (token) => ({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
})

/**
 * Fetch notifications for the current user.
 * @param {Object} user - The user object.
 * @returns {Promise<Object>} - The notifications data.
 */
export const fetchNotificationsApi = async (user) => {
  const config = getAuthConfig(user.token)
  const endpoint = user._officialBrand
    ? `/v1/officialBrands/notification`
    : `/v1/notification`
  const { data } = await Axios.get(endpoint, config)
  return data
}

/**
 * Mark all notifications as read.
 * @param {Object} user - The user object.
 * @returns {Promise<Object>} - The API response data.
 */
export const markAllAsReadApi = async (user) => {
  const config = getAuthConfig(user.token)
  let endpoint = ""
  if (user._admin) {
    endpoint = "/v1/notification/markAllAsRead"
  } else if (user._officialBrand) {
    endpoint = "/v1/officialBrands/notification/markAllAsRead"
  } else {
    throw new Error(
      "User role not supported for marking all notifications as read."
    )
  }
  const { data } = await Axios.patch(endpoint, {}, config)
  return data
}

/**
 * Mark a single notification as read.
 * @param {Object} user - The user object.
 * @param {string} notificationId - The ID of the notification.
 * @returns {Promise<Object>} - The API response data.
 */
export const markNotificationAsReadApi = async (user, notificationId) => {
  const config = getAuthConfig(user.token)
  let endpoint = ""
  if (user._admin) {
    endpoint = `/v1/notification/${notificationId}/read`
  } else if (user._officialBrand) {
    endpoint = `/v1/officialBrands/notification/${notificationId}/read`
  } else {
    throw new Error(
      "User role not supported for marking a notification as read."
    )
  }
  const { data } = await Axios.patch(endpoint, {}, config)
  return data
}
