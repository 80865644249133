export const config = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))

  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: userInfo ? `Bearer ${userInfo.token}` : "",
    },
  }
}
